import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import { useWindowSize } from '../../helpers/useWindowSize'
import { sortDates } from '../../helpers/dates'

const Layout = loadable(() => import('../../components/Layout'))
const SEO = loadable(() => import('../../components/Seo'))
const Gallery = loadable(() => import('../../components/PageBuilderGallery'))
const SplitPage = loadable(() => import('../../components/SplitPage'))
const PageBuilder = loadable(() => import('../../components/PageBuilder'))
const Card = loadable(() => import('./Card'))
const MobileBanner = loadable(() => import('./MobileBanner'))

export const query = graphql`
  query RetreatQuery($_id: String!) {
    page: sanityRetreat(_id: { eq: $_id }) {
      _id
      title
      details
      highlights
      highlightsAbbr
      dates {
        _key
        startDate
        endDate
        price
        prePaidPrice
        standardPrice
        bookingID
        spaces
      }
      _rawBgImg(resolveReferences: { maxDepth: 10 })
      _rawGallery(resolveReferences: { maxDepth: 30 })
      _rawPageBuilder(resolveReferences: { maxDepth: 10 })
      _rawDescription
      _rawIncludes(resolveReferences: { maxDepth: 10 })
      _rawAdditionaLinformation(resolveReferences: { maxDepth: 10 })
      _rawHosts(resolveReferences: { maxDepth: 10 })
      seo {
        metaTitle
        description
        canonical
        image {
          asset {
            url
          }
        }
        noIndex
      }
    }
  }
`

const RetreatSingle = ({ location, data: { page }, pageContext }) => {
  const [displayBanner, setDisplayBanner] = useState(true)
  const wrapper = useRef()
  const windowSize = useWindowSize()

  const _id = pageContext._id.replace('__i18n_it', '')
  const hrefLang = pageContext.allHrefLangs.filter(lang => lang._id === _id)[0].langs

  const pageId = page._id

  const block = {
    bgImg: page._rawBgImg,
    gallery: page._rawGallery,
    heading: true,
  }
  const meta = [
    { name: 'robots', content: `${page.seo?.noIndex ? 'noindex' : 'all'}` },
  ]

  const dates = sortDates(page.dates)

  const handleBannerDisplay = () => {
    if (typeof window === 'undefined') return

    const scrollHeight = wrapper.current?.clientHeight
    const scrollOffset = window.innerHeight
    const scrollDist =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop

    if (scrollDist > scrollHeight - scrollOffset) {
      setDisplayBanner(false)
    } else {
      setDisplayBanner(true)
    }
  }


  useEffect(() => {
    window.addEventListener('scroll', handleBannerDisplay)

    return () => window.removeEventListener('scroll', handleBannerDisplay)
  }, [wrapper])

  return (
    <>
      <SEO
        title={page.title}
        metaTitle={page.seo?.metaTitle}
        description={page.seo?.description}
        image={page.seo?.image?.asset?.url}
        meta={meta}
        hrefLang={hrefLang}
      />
      <Layout location={location || ''}>
        <div ref={wrapper}>
          <Gallery block={block} />
          <SplitPage
            title={page.title}
            details={page.details}
            reviewId={page._id}
            description={page._rawDescription}
            includes={page._rawIncludes}
            additional={page._rawAdditionaLinformation}
            host={page._rawHosts}
          >
            <Card
              highlights={page.highlights}
              dates={dates}
              page={`Retreat: ${page.title}`}
            />
          </SplitPage>
          {page._rawPageBuilder && (
            <PageBuilder pageId={pageId} pageBuilder={page._rawPageBuilder} />
          )}
          {windowSize.width < 1024 && (
            <MobileBanner
              dates={dates}
              highlights={page.highlightsAbbr}
              displayBanner={displayBanner}
              page={`Retreat: ${page.title}`}
            />
          )}
        </div>
      </Layout>
    </>
  )
}

export default RetreatSingle

RetreatSingle.defaultProps = {
  location: {},
  data: {
    _id: null,
    title: null,
    price: null,
    cardTitle: null,
    highlights: null,
    highlightsAbbr: null,
    details: null,
    _rawBgImg: null,
    _rawGallery: null,
    _rawPageBuilder: null,
    _rawDescription: null,
    _rawIncludes: null,
    _rawAdditionaLinformation: null,
    _rawHosts: null,
    seo: {
      metaTitle: null,
      description: null,
      canonical: null,
      image: {},
      noIndex: false,
    },
  },
}

RetreatSingle.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    page: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.string,
      cardTitle: PropTypes.string,
      highlights: PropTypes.array,
      highlightsAbbr: PropTypes.string,
      details: PropTypes.array,
      _rawBgImg: PropTypes.object,
      _rawGallery: PropTypes.object,
      _rawPageBuilder: PropTypes.array,
      _rawDescription: PropTypes.array,
      _rawIncludes: PropTypes.array,
      _rawAdditionaLinformation: PropTypes.array,
      _rawHosts: PropTypes.object,
      seo: PropTypes.shape({
        metaTitle: PropTypes.string,
        description: PropTypes.string,
        canonical: PropTypes.string,
        image: PropTypes.object,
        noIndex: PropTypes.bool,
      }),
    }),
  }),
}
